import React from 'react';
import ChallengeForm from './ChallengeForm';
import { CHALLENGE_REVIEW_STATUS, CHALLENGE_TYPE_DETAILS } from '../../../constants';
import { GetFilterNameFromNumber } from "../../../helpers/Filters";
import { UserInfoContext } from "contexts/UserInfoContext";
import { OpenLocationCode } from "open-location-code";

const CreateChallengeWrapper = () => {
  const data = React.useContext(UserInfoContext);
  const userId = data.user.id;
  const { userLocation } = React.useContext(UserInfoContext);

  const primaryTypeDetails = CHALLENGE_TYPE_DETAILS[GetFilterNameFromNumber(0)];
  let latitude = userLocation?.lat ?? 41.895081;
  let longitude = userLocation?.lng ?? -87.629879;
  let displayedAddress = "Chicago, IL, USA";
  var olc = new OpenLocationCode();
  var globalCode = olc.encode(latitude, longitude);
  var compoundCode = globalCode.slice(4, 11) + " " + displayedAddress;
  var fullAddress = { compoundCode: compoundCode, globalCode: globalCode, displayedAddress: displayedAddress };

  let defaultChallenge = {
    id: "0",
    userId: userId,
    title: "",
    description: "",
    points: 10,
    pointHistory: [{
      startTime: new Date(0),
      endTime: new Date(100000000000000),
      pointValue: 10
    }],
    challengeType: 0,
    challengeSubTypes: primaryTypeDetails?.subTypes,
    lat: latitude,
    long: longitude,
    radius: 60,
    address: fullAddress,
    noLocation: false,
    externalLinks: [],
    youTubeLink: null,
    photoAttribution: userId,
    photoAttributionLicense: "Roamli",
    isPersonal: true,
    active: false,
    blockDeactivate: false,
    isOfficialRoamliPackage: false,
    challengeReviewStatus: CHALLENGE_REVIEW_STATUS.IN_REVIEW,
    textAnswer: [],
    textQuestion: null,
  };

  const [resetCount, setResetCount] = React.useState(0);

  const resetForm = () => {
    setResetCount(resetCount + 1);
  }

  return <ChallengeForm
    key={resetCount}
    challenge={defaultChallenge}
    createMode={true}
    resetFormHandler={resetForm} />
}

export default CreateChallengeWrapper;