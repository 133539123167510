import React, { Component, useRef, useState, useEffect } from 'react';
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Circle,
  Polyline,
} from 'google-maps-react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import '../../GoogleMap/ViewPackageMap/ViewPackageMap.css';
import ViewPackageSidebar from 'views/pages/viewPackage/ViewPackageSidebar';
import 'views/pages/viewPackage/ViewPackage.css';
import { COLOR, DISTANCE, CHALLENGE_PACKAGE_TYPE, CHALLENGE_ANSWER_STATUS } from '../../../constants';
import { GetIconColorPackage } from '../../../helpers/Category';
import ViewPackageMapInfoWindow from './ViewPackageMapInfoWindow';
import ViewPackageDetailModal from 'views/pages/viewPackage/ViewPackageDetailModal';
import ViewPackageChallengeList from 'views/pages/viewPackage/ViewPackageChallengeList';
import ViewPackageUserInfoRow from 'views/pages/viewPackage/ViewPackageUserInfoRow';
import ShowOnly from 'components/Transitions/ShowOnly';
import ViewPackageAppButtonSection from 'views/pages/viewPackage/ViewPackageAppButtonSection';
import ViewPackageImageHeader from 'views/pages/viewPackage/ViewPackageImageHeader';
import ViewPackageDescription from 'views/pages/viewPackage/ViewPackageDescription';
import { computeDestinationPoint } from 'geolib';
import { getMarkerIcon, getUserLocationIcon, getUserLocationCircle } from 'helpers/Marker';
import { HorizontalMapScroller } from 'components/Scrollers/HorizontalMapScroller';
import SubmitTextAnswerModal from 'components/ChallengeSubmission/SubmitTextAnswerModal';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import { GetSubmissionsByChallengeIDMap } from 'helpers/ChallengePackageAccess';
import { IsChallengeSubmittedInPackage } from 'helpers/ChallengePackage';
import SubmitPhotoAnswerModal from 'components/ChallengeSubmission/SubmitPhotoAnswerModal';
import { IsChallengeTextAnswer } from 'helpers/Challenge';
import { useAuth0 } from '@auth0/auth0-react';
import { FILTERS } from 'contexts/ViewPackageContext/ViewPackageContext';
import { IsValidTime } from 'helpers/ChallengePackage';

class MarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
    this.props.challenges.forEach((challenge) => {
      this[challenge.challengeId] = React.createRef();
    })
    this.getMarkers = this.getMarkers.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props.challengePackageAccess) !== JSON.stringify(nextProps.challengePackageAccess)) {
      return true;
    }

    if (this.props.hideLine !== nextProps.hideLine) {
      return true;
    }

    if (this.props.type !== nextProps.type) {
      return true;
    }

    if (JSON.stringify(this.props.challenges) === JSON.stringify(nextProps.challenges) && this.markersRendered) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }

  getScavengerHuntMarkers = () => {
    const challengeMarkers = [];
    const submissionsMap = GetSubmissionsByChallengeIDMap(this.props.challengePackageAccess);

    if (this.props.challenges) {
      this.props.challenges.filter((chal) => { return !chal.challenge.noLocation }).forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starPurple.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );

        let markerColor = COLOR.ROAMLI_BLUE_HEX;
        const submission = submissionsMap[challenge.challengeId];

        switch (submission?.status) {
          case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
            markerColor = COLOR.ROAMLI_YELLOW_HEX;
            break;
          case CHALLENGE_ANSWER_STATUS.APPROVED:
            markerColor = COLOR.SUCCESS_GREEN_HEX;
            break;
          case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
          case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
            markerColor = COLOR.ALERT_RED_HEX;
            break;
        }

        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon(markerColor)}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                fontSize: "12px",
                className: "markerLabel",
              }}
          >
          </Marker>
        challengeMarkers.push(marker);
      })
    }
    return challengeMarkers;
  };

  getChallengeTrackMarkers = () => {
    const challengeMarkers = [];
    const submissionsMap = GetSubmissionsByChallengeIDMap(this.props.challengePackageAccess);
    if (this.props.challenges) {
      this.props.challenges.filter((chal) => { return !chal.challenge.noLocation }).forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starOrange.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );

        let markerColor = COLOR.ROAMLI_BLUE_HEX;
        const submission = submissionsMap[challenge.challengeId];

        switch (submission?.status) {
          case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
            markerColor = COLOR.ROAMLI_YELLOW_HEX;
            break;
          case CHALLENGE_ANSWER_STATUS.APPROVED:
            markerColor = COLOR.SUCCESS_GREEN_HEX;
            break;
          case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
          case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
            markerColor = COLOR.ALERT_RED_HEX;
            break;
        }

        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId + "markers"}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon(markerColor)}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                // fontSize: "12px",
                // className: "markerLabel",
              }}
          />
        challengeMarkers.push(marker);
      })
    }
    if (!this.props.hideLine) {
      challengeMarkers.push(<Polyline
        map={this.props.map}
        google={this.props.google} Î
        onClick={() => { }}
        path={
          this.props.challenges.filter((chal) => { return !chal.challenge.noLocation }).map(
            (c) => { return { lat: c.centerLat, lng: c.centerLong } })
        }
        options={{ strokeColor: COLOR.ROAMLI_PURPLE_HEX, strokeWeight: 5 }} />);
    }

    return challengeMarkers;
  }

  getMarkers() {
    const challengeMarkers =
      this.props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
        this.getScavengerHuntMarkers()
        : this.getChallengeTrackMarkers();
    return challengeMarkers
  }

  render() {
    const challengeMarkers = this.getMarkers()

    return (
      <>
        {challengeMarkers}
      </>
    )
  }
}

class ScavengerHuntRadiusMarker extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const centerPosition = {
      lat: this.props.activeChallenge?.centerLat,
      lng: this.props.activeChallenge?.centerLong
    };

    let colorPackage = GetIconColorPackage(this.props.activeChallenge?.challengeType ?? 25);
    return (
      <>
        {
          this.props.activeChallenge &&
          <Circle
            map={this.props.map}
            google={this.props.google}
            onClick={() => { }}
            center={centerPosition}
            radius={this.props.activeChallenge.radius ?? DISTANCE.SCAVENGER_HUNT_RADIUS}
            strokeColor={colorPackage.color}
            fillColor={colorPackage.backgroundColor} />
        }
      </>
    )
  }
}

const ControlPosition = ({ coordinate, onClick }) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '110px',
        right: '10px',
        backgroundColor: 'white',
        padding: '5px',
        borderRadius: '3px',
        cursor: 'pointer',
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
      }}
      onClick={() => onClick(coordinate)}
    >
      <i className="fa fa-crosshairs" style={{
        width: 30,
        height: 30,
        fontSize: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#666666"
      }}></i>
    </div>
  );
};

const ControlFullscreen = ({ onClick, isFullscreen }) => {
  const icon = isFullscreen ? "fa fa-compress" : "fa fa-expand";
  return (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        padding: '5px',
        borderRadius: '3px',
        cursor: 'pointer',
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
      }}
      onClick={onClick}
    >
      <i className={icon} style={{
        width: 30,
        height: 30,
        fontSize: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#666666"
      }}></i>
    </div>
  );
};

const MapContainer = (props) => {
  const { isAuthenticated } = useAuth0();
  const initialBounds = new window.google.maps.LatLngBounds();
  const initialPosition = { lat: props.lat, lng: props.lng };

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeChallenge, setActiveChallenge] = useState(null);
  const [bounds, setBounds] = useState(initialBounds);
  const [zoom, setZoom] = useState(undefined);
  const [center, setCenter] = useState(initialPosition);
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const [showTextAnswerModal, setShowTextAnswerModal] = useState(false);
  const [showPhotoAnswerModal, setShowPhotoAnswerModal] = useState(false);

  const {
    challengePackageAccess,
    selectedFilter
  } = React.useContext(ViewPackageContext);

  const markersList = useRef([]);
  const map = useRef(null);

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    props.challengePackage?.challenges.forEach((challenge) => {
      const location = new window.google.maps.LatLng(
        challenge.centerLat,
        challenge.centerLong
      );
      bounds.extend(location);
    });

    if (!eventHasStarted() && props.challengePackage.challenges[0]) {
      const { centerLat: lat, centerLong: lng, radius } = props.challengePackage.challenges[0];
      const radNorth = computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 0);
      const radSouth = computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 180);
      const radEast = computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 90);
      const radWest = computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 270);

      bounds.extend(new window.google.maps.LatLng(radNorth.latitude, radNorth.longitude));
      bounds.extend(new window.google.maps.LatLng(radSouth.latitude, radSouth.longitude));
      bounds.extend(new window.google.maps.LatLng(radEast.latitude, radEast.longitude));
      bounds.extend(new window.google.maps.LatLng(radWest.latitude, radWest.longitude));
    }

    setBounds(bounds);
  }, [props.challenges, props.challengePackage]);

  const eventHasStarted = () => {
    return !props.challengePackage.startTime ||
      (props.challengePackage.startTime && new Date().getTime() >= new Date(props.challengePackage.startTime).getTime());
  };

  const handleCenterChanged = (map) => {
    if (!map?.center || !props.updateCoordinatesHandler) return;
    props.updateCoordinatesHandler({ latitude: map.center.lat(), longitude: map.center.lng() });
  };

  const onMarkerClick = (props, marker, e, challenge) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
    setActiveChallenge(challenge);
  };

  const onClose = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker(null);
      setActiveChallenge(null);
    }
  };

  const setActiveChallengeFromOutsideOfMap = (challenge) => {
    if (challenge) {
      const marker = markersList?.current[challenge.challengeId]?.current?.marker;
      if (marker) {
        onMarkerClick({}, marker, null, challenge);
      }
      if (challenge?.challenge?.noLocation) {
        setActiveChallenge(challenge);
        setShowInfoWindow(false);
        setActiveMarker(null);
        setShowDetailModal(true);
      }
    }
  };

  React.useEffect(() => {
    setActiveChallenge(null);
    setShowInfoWindow(false);
    setActiveMarker(null);
    setShowDetailModal(false);
  }, [selectedFilter])

  const handleCenterMap = (coordinate) => {
    const mapInstance = map.current.map;
    mapInstance.panTo(coordinate);
    mapInstance.setZoom(17);
  };

  const isActiveChallengeSubmitted = React.useMemo(() => {
    return IsChallengeSubmittedInPackage(activeChallenge?.challenge, challengePackageAccess)
  }, [activeChallenge, challengePackageAccess])

  const isValidForSubmission = IsValidTime(props.challengePackage);

  return (
    <div className="viewPackageRoot" style={{ overflow: 'hidden' }}>
      {props.isTabletOrMobile && (
        <>
          <ShowOnly targetId="viewChallengePackageListTop" selfId="viewPackageMapMobileScrollToTop">
            <div
              className="viewPackageMapMobileScrollToTop"
              id="viewPackageMapMobileScrollToTop"
              onClick={() => {
                document.getElementById("viewPackageUserRow").scrollIntoView({ behavior: "smooth" });
              }}
            >
              <div className="arrow" />
            </div>
          </ShowOnly>
          <ViewPackageImageHeader
            challengePackage={props.challengePackage}
            showArrow={props.isTabletOrMobile}
          />
          <ViewPackageUserInfoRow userData={props.userData} challengePackage={props.challengePackage} />
          <ViewPackageDescription
            challengePackage={props.challengePackage}
            updatePackageHandler={props.updatePackageHandler}
          />
        </>
      )}
      {props.isTabletOrMobile && <ViewPackageAppButtonSection />}
      {props.isTabletOrMobile && !props.challengePackage.hideFromList && props.createdChallengePackages && props.createdChallengePackages.length > 1 && (
        <Card style={{ marginBottom: 0, width: '100%' }}>
          <CardHeader style={{ padding: '1.25rem 15px' }}>
            <h5 className="h3 mb-0">{`More Events by ${props.userData.name}`}</h5>
          </CardHeader>
          <CardBody style={{ padding: '10px 0 20px 0' }}>
            <HorizontalMapScroller challengePackages={props.createdChallengePackages} />
          </CardBody>
        </Card>
      )}
      <div className={`map-container ${props.isTabletOrMobile ? !isMapFullscreen ? "viewPackageMapMobile" : "viewPackageMapFullscreen" : "viewPackageMap"}`}>
        <ViewPackageSidebar
          challengePackage={props.challengePackage}
          onSideMenuClickHandler={setActiveChallengeFromOutsideOfMap}
          updatePackageHandler={props.updatePackageHandler}
          userData={props.userData}
        />
        <div className={`map-container viewPackageMap`}>
          <Map
            ref={map}
            className={"googleMap"}
            google={props.google}
            containerStyle={isMapFullscreen ? props.fullscreenStyle : props.containerStyle}
            zoom={zoom}
            center={center}
            bounds={bounds}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            onClick={onClose}
            onCenterChanged={(t, mapInstance, coord) => { handleCenterChanged(mapInstance) }}
            styles={[{ "featureType": "poi.business", "stylers": [{ "visibility": "off" }] }]}
          >
            {props.isTabletOrMobile && (
              <ControlFullscreen
                onClick={() => { setIsMapFullscreen(!isMapFullscreen); }}
                isFullscreen={isMapFullscreen}
              />
            )}
            {props.userLocation && (
              <ControlPosition coordinate={props.userLocation} onClick={handleCenterMap} />
            )}
            {props.userLocation && (
              <Marker
                icon={getUserLocationCircle()}
                position={props.userLocation}
                optimized
                map={map}
                google={props.google}
              />
            )}
            {props.userLocation && (
              <Marker
                icon={getUserLocationIcon()}
                position={props.userLocation}
                optimized
                map={map}
                google={props.google}
              />
            )}
            <MarkersList
              ref={markersList}
              google={props.google}
              onClick={onMarkerClick}
              challengePackage={props.challengePackage}
              challengePackageAccess={challengePackageAccess}
              activeChallenge={activeChallenge}
              type={props.type}
              challenges={props.challenges}
              hideLine={props.hideLine || selectedFilter !== FILTERS.ALL}
            />
            {props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT && (
              <ScavengerHuntRadiusMarker
                google={props.google}
                activeChallenge={activeChallenge}
              />
            )}
            {!eventHasStarted() && !activeChallenge && (
              <ScavengerHuntRadiusMarker
                google={props.google}
                activeChallenge={props.challengePackage.challenges[0]}
              />
            )}
            <ViewPackageMapInfoWindow
              marker={activeMarker}
              visible={showInfoWindow}
              onClose={onClose}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', minHeight: 200, minWidth: 200 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column" }}>
                  <div style={{ height: 150, width: 250, overflow: 'hidden' }}>
                    {activeChallenge?.picChallengeUrl ? (
                      <img src={activeChallenge?.picChallengeUrl} style={{ width: '100%' }} />
                    ) : (
                      <h6 style={{ textAlign: 'center' }}>No Image</h6>
                    )}
                  </div>
                  <h4 style={{ textAlign: 'left', padding: 10, width: '100%', paddingBottom: 0, marginBottom: 0 }}>
                    {activeChallenge?.title}
                  </h4>
                  {activeChallenge?.challenge?.address?.displayedAddress && (
                    <small style={{ textAlign: 'left', paddingLeft: 10, paddingRight: 10, width: '100%' }}>
                      {activeChallenge?.challenge?.address?.displayedAddress}
                    </small>
                  )}
                  <div className={"viewPackageInfoWindowButtonContainer"}>
                    {isAuthenticated && isValidForSubmission && <Button
                      style={{ width: '50%', marginBottom: 0, marginTop: 10, marginRight: 0, borderRadius: 0 }}
                      color="warning"
                      type="button"
                      disabled={isActiveChallengeSubmitted}
                      onClick={() => { IsChallengeTextAnswer(activeChallenge?.challenge) ? setShowTextAnswerModal(true) : setShowPhotoAnswerModal(true); }}
                    >
                      {isActiveChallengeSubmitted ? "Submitted!" : "Submit"}
                    </Button>}
                    <Button
                      style={{
                        width: isAuthenticated && isValidForSubmission ? '50%' : '100%',
                        marginBottom: 0,
                        marginTop: 10,
                        marginLeft: 0,
                        borderRadius: 0
                      }}
                      color="info"
                      type="button"
                      onClick={() => { setShowDetailModal(true); }}
                    >
                      More Info
                    </Button>
                  </div>
                </div>
              </div>
            </ViewPackageMapInfoWindow>
          </Map>
          {!props.isTabletOrMobile && !props.challengePackage.hideFromList && (
            <HorizontalMapScroller challengePackages={props.createdChallengePackages} />
          )}
        </div>
        <ViewPackageDetailModal
          show={showDetailModal}
          showHandler={setShowDetailModal}
          activeChallengeHandler={setActiveChallengeFromOutsideOfMap}
          challenges={props.challenges}
          challenge={activeChallenge}
          openSubmissionModalHandler={() => {
            IsChallengeTextAnswer(activeChallenge?.challenge) ?
              setShowTextAnswerModal(true) :
              setShowPhotoAnswerModal(true);
          }}
        />
        {
          IsChallengeTextAnswer(activeChallenge?.challenge) && <SubmitTextAnswerModal
            challenge={activeChallenge}
            visible={showTextAnswerModal}
            headerText={"Submit an Answer"}
            closeHandler={() => { setShowTextAnswerModal(false); }}
            challengePackage={props.challengePackage}
          />
        }
        {
          !IsChallengeTextAnswer(activeChallenge?.challenge) &&
          IsChallengeTextAnswer(activeChallenge?.challenge) !== undefined &&
          <SubmitPhotoAnswerModal
            challenge={activeChallenge}
            visible={showPhotoAnswerModal}
            headerText={"Submit a Photo"}
            closeHandler={() => { setShowPhotoAnswerModal(false); }}
            challengePackage={props.challengePackage}
          />
        }
      </div>
      {props.isTabletOrMobile && eventHasStarted() && (
        <ViewPackageChallengeList
          challengePackage={props.challengePackage}
          challengeClickHandler={(challenge) => {
            setActiveChallengeFromOutsideOfMap(challenge);
            setShowDetailModal(true);
          }}
        />
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo'
})(MapContainer);