import React from 'react';

export const FILTERS = {
  ALL: "all",
  REMAINING: "remaining",
  APPROVED: "approved",
  IN_REVIEW: "in_review",
  DECLINED: "declined"
}

const ViewPackageContext = React.createContext({
  challengePackage: null,
  createdChallengePackages: [],
  noPackageFound: false,
  createdByUser: null,
  newLookUpCode: null,
  fullyLoaded: false,
  challengePackageAccess: null,
  selectedFilter: FILTERS.ALL,
  filteredChallenges: undefined,
  lookUpNewEvent: () => { },
  setNewLookUpCode: () => { },
  GetPackage: () => { },
  resetState: () => { },
  setChallengePackageAccess: () => { },
  setChallengePackage: () => { },
  setSelectedFilter: () => { },
  GetFilteredChallenges: () => { },
  nearbyChallengeTracks: [],
  nearbyScavengerHunts: [],
  nearbyChallengePackagesLoaded: true,
  searchQuery: null,
  setSearchQuery: () => { },
  GetPoints: () => { },
  userLocation: null,
});

export { ViewPackageContext };