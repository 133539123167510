/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
  Modal
} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// core components
import { CHALLENGE_REVIEW_STATUS_DISPLAY, CHALLENGE_TYPE_DETAILS, ANSWER_TYPE, CHALLENGE_REVIEW_STATUS, COLOR, PATH } from '../../../constants';
import GetProtectedAPIClient from '../../../api/protectedAPIClient';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import ExternalLinks from '../../ExternalLinks/ExternalLinks';
import './ChallengeForm.css';
import { IsValidUrl } from "../../../helpers/Validations";
import { IsEmpty } from "../../../helpers/Strings";
import { GetFilterNameFromNumber } from "../../../helpers/Filters";
import { GetCurrentPointValue } from "../../../helpers/Challenge";
import { useParams, useHistory } from "react-router-dom";
import ChallengeTypeSelection from "../../ChallengeType/ChallengeTypeSelection";
import ChallengeFormFieldRow from "./ChallengeFormFieldRow";
import ChallengeImageField from "./ChallengeImageField";
import GoogleApiWrapper from '../../GoogleMap/UpdateMap';
import { OpenLocationCode } from "open-location-code";
import Breadcrumbs from '../../Headers/Breadcrumbs';
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import useBreakpoints from "hooks/Responsive";
import TextAnswers from "components/TextAnswers/TextAnswers";
import YouTubeLinkRow from "components/ExternalLinks/YouTubeLinkRow";
import POIAutoComplete from "./POIAutoComplete";

const ChallengeForm = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const roles = user ? user["http://www.roamli.com/roles"] : null;
  const authenticatedAdmin = isAuthenticated && (roles.includes('Admin') || roles.includes('SuperAdmin'));

  const data = React.useContext(UserInfoContext);
  const { userLocation } = React.useContext(UserInfoContext);
  const { notify } = React.useContext(NotificationContext);
  const userId = data.user.id;
  const { challengeId } = useParams();
  const challenge = props.challenge ? props.challenge : data.challenges.find((c) => { return c.id == challengeId });

  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState(challenge?.title);
  const [description, setDescription] = React.useState(challenge?.description);

  const [externalLinks, setExternalLinks] = React.useState(challenge?.externalLinks);
  const [youTubeLink, setYouTubeLink] = React.useState(challenge?.youTubeLink);
  const [textAnswer, setTextAnswer] = React.useState(challenge?.textAnswer ?? []);
  const [textQuestion, setTextQuestion] = React.useState(challenge?.textQuestion ?? null);

  const [isActive, setIsActive] = React.useState(challenge?.active);
  const [isPersonal, setIsPersonal] = React.useState(challenge?.isPersonal ?? true);
  const [challengeReviewStatus, setChallengeReviewStatus] = React.useState(challenge?.challengeReviewStatus ?? CHALLENGE_REVIEW_STATUS.IN_REVIEW);
  const [picChallengeUrl, setPicChallengeUrl] = React.useState(!IsEmpty(challenge?.picChallengeUrl) ? challenge?.picChallengeUrl : null);

  const [challengeType, setChallengeType] = React.useState(challenge ? challenge?.challengeType : 0);
  let baseSecondaryTypes = CHALLENGE_TYPE_DETAILS[GetFilterNameFromNumber(challengeType)];
  const [challengeSubTypes, setChallengeSubTypes] = React.useState(challenge?.challengeSubTypes ?? baseSecondaryTypes?.subTypes);

  const [points, setPoints] = React.useState(challenge ? GetCurrentPointValue(challenge) : 10);
  const [radius, setRadius] = React.useState(challenge ? challenge.radius : 60);

  const [latitude, setLatitude] = React.useState(challenge ? challenge.lat : (userLocation?.lat || 41.895081));
  const [longitude, setLongitude] = React.useState(challenge ? challenge.long : (userLocation?.lng || -87.629879));
  const [address, setAddress] = React.useState(challenge ? challenge.address?.displayedAddress : null);
  const [noLocation, setNoLocation] = React.useState(challenge ? challenge.noLocation : false);

  const [image, setImage] = React.useState(null);
  const [useLocalImage, setUseLocalImage] = React.useState(false);
  const [imageKey, setImageKey] = React.useState(0);

  const [photoAttribution, setPhotoAttribution] = React.useState(challenge ? challenge.photoAttribution?.toString() === userId?.toString() ? null : challenge.photoAttribution : null);
  const [photoAttributionLicense, setPhotoAttributionLicense] = React.useState(challenge ? challenge.photoAttribution?.toString() === userId?.toString() ? null : challenge.photoAttributionLicense : null);
  const [isNotMyPhoto, setIsNotMyPhoto] = React.useState(challenge ? challenge.photoAttribution?.toString() !== userId?.toString() : false);

  const [alert, setalert] = React.useState(false);
  const { isTabletOrMobile } = useBreakpoints();

  const [answerType, setAnswerType] = React.useState(!IsEmpty(challenge?.textQuestion) ? ANSWER_TYPE.TEXT : ANSWER_TYPE.PICTURE);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  const formatTime = (dateTime) => {
    if (dateTime === "") { return "" };
    const date = new Date(dateTime);
    return date.getFullYear() + "-" + formatDateTimeNumber(date.getMonth() + 1) + "-" + formatDateTimeNumber(date.getDate()) + "T" + formatDateTimeNumber(date.getHours()) + ":" + formatDateTimeNumber(date.getMinutes()) + ":00";
  }

  const formatDateTimeNumber = (time) => {
    if (time?.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  const formattedLinks = () => {
    if (!areLinksValid()) {
      return challenge.externalLinks
    }
    return externalLinks;
  }

  const areLinksValid = () => {
    let areValid = true;
    externalLinks.forEach((link) => {
      if (!IsValidUrl(link.url) || IsEmpty(link.label)) {
        areValid = false;
      }
    })

    return areValid;
  }

  const isYouTubeLinkValid = () => {
    if (!youTubeLink) return true;
    if (youTubeLink?.url) return true;
    //Label and Description are optional
    return false;
  }

  const formattedYouTubeLink = () => {
    if (!isYouTubeLinkValid()) {
      return challenge?.youTubeLink
    }
    return youTubeLink;
  }

  const getCreateObject = () => {
    let activeDate = new Date();
    activeDate.setDate(activeDate.getDate() - 1);
    var olc = new OpenLocationCode();
    var globalCode = olc.encode(latitude, longitude);
    var compoundCode = globalCode.slice(4, 11) + " " + address;
    var fullAddress = { compoundCode: compoundCode, globalCode: globalCode, displayedAddress: noLocation ? "Anywhere" : address };

    const createObject = {
      title: IsEmpty(title) ? null : title,
      description: IsEmpty(description) ? null : description,
      challengeType: challengeType,
      challengeSubTypes: challengeSubTypes,
      challengeLocationType: "",
      activeOn: activeDate.toUTCString(),
      expiresOn: new Date(100000000000000).toUTCString(),
      sponsoredBy: "",
      businessId: "",
      createdBy: userId,
      lat: latitude,
      long: longitude,
      address: fullAddress,
      radius: radius,
      points: points,
      active: isActive,
      isPersonal: isPersonal,
      picChallengeUrl: null,
      photoAttribution: isNotMyPhoto ? photoAttribution ?? userId : userId,
      photoAttributionLicense: isNotMyPhoto ? photoAttributionLicense ?? "Roamli" : "Roamli",
      descriptionWrittenBy: IsEmpty(description) ? null : userId,
      challengeReviewStatus: challengeReviewStatus,
      externalLinks: externalLinks,
      youTubeLink: youTubeLink,
      noLocation: noLocation,
      textAnswer: answerType === ANSWER_TYPE.TEXT ? textAnswer : null,
      textQuestion: answerType === ANSWER_TYPE.TEXT ? textQuestion : null,
    };

    let createFullCommand = {
      createCommand: createObject,
      mediaType: ANSWER_TYPE.PICTURE,
      mediaBytes: image ? image?.split("base64,")[1] : null,
      mediaName: image ? "portalSubmitted" : null,
    }

    return createFullCommand
  }

  const getDuplicateObject = () => {
    let activeDate = new Date();
    activeDate.setDate(activeDate.getDate() - 1);
    var olc = new OpenLocationCode();
    var globalCode = olc.encode(latitude, longitude);
    var compoundCode = globalCode.slice(4, 11) + " " + address;
    var fullAddress = { compoundCode: compoundCode, globalCode: globalCode, displayedAddress: noLocation ? "Anywhere" : address };

    const createObject = {
      title: IsEmpty(title) ? null : title,
      description: IsEmpty(description) ? null : description,
      challengeType: challengeType,
      challengeSubTypes: challengeSubTypes,
      challengeLocationType: "",
      activeOn: activeDate.toUTCString(),
      expiresOn: new Date(100000000000000).toUTCString(),
      sponsoredBy: "",
      businessId: "",
      createdBy: userId,
      lat: latitude,
      long: longitude,
      address: fullAddress,
      radius: radius,
      points: points,
      active: isActive,
      isPersonal: isPersonal,
      picChallengeUrl: image ? null : picChallengeUrl,
      photoAttribution: isNotMyPhoto ? photoAttribution ?? userId : userId,
      photoAttributionLicense: isNotMyPhoto ? photoAttributionLicense ?? "Roamli" : "Roamli",
      descriptionWrittenBy: IsEmpty(description) ? null : userId,
      challengeReviewStatus: challengeReviewStatus,
      externalLinks: externalLinks,
      youTubeLink: youTubeLink,
      noLocation: noLocation,
      textAnswer: answerType === ANSWER_TYPE.TEXT ? textAnswer : null,
      textQuestion: answerType === ANSWER_TYPE.TEXT ? textQuestion : null,
    };

    let createFullCommand = {
      createCommand: createObject,
      mediaType: ANSWER_TYPE.PICTURE,
      mediaBytes: image ? image?.split("base64,")[1] : null,
      mediaName: image ? "portalSubmitted" : null,
    }

    return createFullCommand
  }

  const getUpdateObject = () => {
    var olc = new OpenLocationCode();
    var globalCode = olc.encode(latitude, longitude);
    var compoundCode = globalCode.slice(4, 11) + " " + address;
    var fullAddress = { compoundCode: compoundCode, globalCode: globalCode, displayedAddress: noLocation ? "Anywhere" : address };

    return {
      id: challenge.id,
      userId: userId,
      title: IsEmpty(title) ? null : title,
      description: IsEmpty(description) ? null : description,
      points: points,
      challengeType: challengeType,
      challengeSubTypes: challengeSubTypes,
      latitude: latitude,
      longitude: longitude,
      radius: Math.floor(parseInt(radius)),
      address: fullAddress,
      noLocation: noLocation,
      externalLinks: formattedLinks(),
      youTubeLink: formattedYouTubeLink(),
      photoAttribution: isNotMyPhoto ? photoAttribution ?? userId : userId,
      photoAttributionLicense: isNotMyPhoto ? photoAttributionLicense ?? "Roamli" : "Roamli",
      mediaType: ANSWER_TYPE.PICTURE,
      mediaBytes: image ? image?.split("base64,")[1] : null,
      mediaName: image ? (userId + "-portalSubmitted").toLowerCase() : null,
      isPersonal: isPersonal,
      active: isActive,
      challengeReviewStatus: challengeReviewStatus,
      picChallengeUrl: picChallengeUrl,
      textAnswer: answerType === ANSWER_TYPE.TEXT ? textAnswer : null,
      textQuestion: answerType === ANSWER_TYPE.TEXT ? textQuestion : null,
    }
  }

  const formIsValidated = () => {
    let errors = [];

    if (props.createMode && !props.creatingDuplicate && !image) {
      errors.push("Select an Image");
    }

    if (title === "" || title === null || title === undefined) {
      errors.push("Enter Valid Title");
    }

    if (description === "" || description === null || description === undefined) {
      errors.push("Enter Valid Description");
    }

    if (isNaN(points) || !points || points < 0) {
      errors.push("Enter Valid Points");
    }

    if (!isValidLatitude()) {
      errors.push("Enter Valid Latitude");
    }

    if (!isValidLongitude()) {
      errors.push("Enter Valid Longitude");
    }

    if (!areLinksValid()) {
      errors.push("One or more External Links are invalid");
    }

    if (!isYouTubeLinkValid()) {
      errors.push("Your YouTube Link is invalid");
    }

    if (isNaN(radius) || !radius || radius < 0) {
      errors.push("Enter Valid Radius");
    }

    if (address === "" || address === null || address === undefined) {
      errors.push("Enter Valid Displayed Address");
    }

    if (answerType === ANSWER_TYPE.TEXT && textAnswer.filter(ta => !IsEmpty(ta)).length === 0) {
      errors.push("Enter at least one Valid Answer, or select 'Submit a Photo' as the Answer Type.");
    }

    if (answerType === ANSWER_TYPE.TEXT && IsEmpty(textQuestion)) {
      errors.push("Enter an Answer Question, or select 'Submit a Photo' as the Answer Type.");
    }

    if (errors.length > 0) {
      notify("warning", "Fix Errors", "Please fix the following errors before submitting:", errors);
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  }

  const handlePlaceSelect = (place) => {
    if (place?.formatted_address) {
      setAddress(place?.formatted_address);
    } else {
      setAddress(null);
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    if (lat && lng) {
      setLatitude(lat);
      setLongitude(lng);
    }
  };

  const handleSubmission = async () => {
    // props.resetFormHandler();
    // successAlert();
    // return;
    setIsLoading(true);
    if (!isAuthenticated || !challenge) {
      notify("danger", "Submission Failed", "Your " + (props.createMode ? "Challenge" : "Updates") + " couldn't be " + (props.createMode ? "created" : "applied") + ". Please try again or contact support. (ERR: 1001)")
      return;
    }
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, accessToken);

      if (props.createMode) {
        let body = props.creatingDuplicate ? getDuplicateObject() : getCreateObject();

        if (formIsValidated()) {
          await challengeAPIClient.post("/Portal/CreateChallenge", body)
            .then(res => {
              if (res.data) {
                console.log("SUCCESS");
                // Update challenges list without needing to reload all challenges
                data.challenges.push(res.data);
                successAlert(res.data);
                setIsLoading(false);
              } else {
                console.log("FAILURE");
                notify("danger", "Create Failed", "Your Challenge couldn't be created. Please try again or contact support. (ERR: 1002)")
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              notify("danger", "Create Failed", "Your Challenge couldn't be created. Please try again or contact support. (Err: 1003)");
              setIsLoading(false);
            })
        }
      } else {
        let body = getUpdateObject();

        if (formIsValidated()) {
          await challengeAPIClient.put("/Portal/UpdateChallenge", body)
            .then(res => {
              if (res.data) {
                console.log("SUCCESS");
                notify("success", "Update Complete!", "Your updates are now live in the app.");
                let updatedChallenges = data.challenges.map(
                  (challenge) => {
                    if (challenge.id === res.data.id) { return res.data }
                    else { return challenge }
                  });
                // Update challenges list without needing to reload all challenges
                data.challenges = updatedChallenges;
                setIsLoading(false);
                props.resetFormHandler();
              } else {
                console.log("FAILURE");
                notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (ERR: 1002)")
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (Err: 1003)");
              setIsLoading(false);
            })
        }
      }

    } catch (e) {
      console.log(e);
      notify("danger", (props.createMode ? "Creation" : "Update") + " Failed", "Your " + (props.createMode ? "Challenge" : "Updates") + " couldn't be " + (props.createMode ? "created" : "applied") + ". Please try again or contact support. (ERR: 1004)")
      setIsLoading(false);
    }
  }

  const primaryTypeDetails = CHALLENGE_TYPE_DETAILS[GetFilterNameFromNumber(challengeType)];

  const getNumberErrorMessage = (value) => {
    if (isNaN(value)) {
      return "Must be a number.";
    }

    if (!value) {
      return "Please enter a value.";
    }

    if (value < 0) {
      return "Must be greater than 0.";
    }
  }

  const isValidLatitude = () => {
    return !isNaN(latitude) && Math.abs(latitude) <= 90;
  }

  const isValidLongitude = () => {
    return !isNaN(longitude) && Math.abs(longitude) <= 180;
  }

  const areLatLongChanged = () => {
    return longitude !== challenge.long || latitude !== challenge.lat;
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setPicChallengeUrl(null);
        setUseLocalImage(true);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  let source = challenge?.picChallengeUrl ? challenge?.picChallengeUrl : challenge?.userSubmittedPicChallengeUrl;
  source = source !== null && source !== "" && !useLocalImage ? source : image;

  const resetPhotoAttribution = () => {
    if (challenge?.photoAttribution?.toString() === userId?.toString()) {
      setPhotoAttribution(null);
      setPhotoAttributionLicense(null);
    } else {
      setPhotoAttribution(challenge?.photoAttribution);
      setPhotoAttributionLicense(challenge?.photoAttributionLicense)
    }
  }

  const isPhotoAttributionChanged = () => {
    if (challenge?.photoAttribution?.toString() === userId?.toString()) {
      return photoAttribution !== null || photoAttributionLicense !== null;
    } else {
      return photoAttribution !== challenge?.photoAttribution || photoAttributionLicense !== challenge?.photoAttributionLicense;
    }
  }

  const successAlert = (createdChallenge) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => { setalert(null); routeChange(PATH.MAIN_CHALLENGE + "/" + createdChallenge.id); props.resetFormHandler(); }}
        onCancel={() => {
          if (props.creatingDuplicate) {
            routeChange(PATH.MAIN_CREATE_CHALLENGE);
          } else {
            props.resetFormHandler();
            setalert(null);
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
          }
        }}
        cancelBtnBsStyle="warning"
        cancelBtnText="Create New Challenge"
        confirmBtnBsStyle="info"
        confirmBtnText="View Created Challenge"
        closeOnClickOutside={false}
        showCancel
        btnSize=""
      >
        Your Challenge is now Live in the App!
      </ReactBSAlert>
    );
  };

  return (
    <>
      <div className="header bg-info pb-6">
        <div className={"ml-3 mb-3 pt-3"}>
          <Breadcrumbs name={"Challenge"} parentName={"Home"} subName={props.createMode ? "Create Challenge" : "Update Challenge"} />
        </div>
      </div>
      <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
        <Card>
          <CardBody>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ marginBottom: 0 }}>{title + " - " + address}</h2>
              {
                props.creatingDuplicate && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h5 style={{ marginRight: 20, marginBottom: 0, color: COLOR.ROAMLI_ORANGE_HEX }}>Make changes then click "Create Challenge" to save this copy.</h5>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      props.duplicateHandler && props.duplicateHandler();
                      document.documentElement.scrollTop = 0;
                      document.scrollingElement.scrollTop = 0;
                    }}
                    disabled={isLoading}
                    style={{ minWidth: 160, minHeight: 55 }}
                  >
                    BACK TO ORIGINAL
                  </Button>
                </div>
              }
              {
                !props.creatingDuplicate && !props.createMode && <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    props.duplicateHandler && props.duplicateHandler();
                    document.documentElement.scrollTop = 0;
                    document.scrollingElement.scrollTop = 0;
                  }}
                  disabled={isLoading}
                  size="md"
                >
                  MAKE A COPY
                </Button>
              }
            </div>
            <Form className="needs-validation" noValidate>
              <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>Photo Details</h4>
              <div className={"form-row mt-1"} style={{ width: '100%' }}>
                <ChallengeImageField
                  key={imageKey}
                  source={source}
                  onChangeImageHandler={onImageChange}
                  resetHandler={
                    () => {
                      setImage(null);
                      setUseLocalImage(false);
                      setImageKey(imageKey + 1);
                      setPicChallengeUrl(challenge?.picChallengeUrl);
                    }
                  }
                  isResetDisabled={image === null} />
              </div>
              <Col md="3" className="ml-0 pl-0">
                <label
                  className="form-control-label"
                  id="tooltipPhotoAttrib"
                >
                  Photo Attribution:
                  <i className="far fa-question-circle ml-1" />
                </label>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioPhotoAttrib1"
                    name="custom-radio-photo-attrib"
                    type="radio"
                    onChange={(e) => { setIsNotMyPhoto(false) }}
                    checked={!isNotMyPhoto}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioPhotoAttrib1"
                  >
                    This is my photo
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioPhotoAttrib2"
                    name="custom-radio-photo-attrib"
                    type="radio"
                    onChange={(e) => { setIsNotMyPhoto(true) }}
                    checked={isNotMyPhoto}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioPhotoAttrib2"
                  >
                    Attribute photo to someone else
                  </label>
                </div>
              </Col>
              <UncontrolledTooltip delay={0} target="tooltipPhotoAttrib">
                If you found the photo online, credit the original taker of the photograph by setting their name or username in the Photo Attribution field and linking to the license in the Photo License URL field.
              </UncontrolledTooltip>
              {
                isNotMyPhoto ?
                  <ChallengeFormFieldRow
                    resetHandler={() => { resetPhotoAttribution() }}
                    isResetDisabled={!isPhotoAttributionChanged()}
                    fields={[
                      {
                        width: "2",
                        label: "Photo Attribution",
                        defaultValue: undefined,
                        placeholder: "No Photo Attribution Set",
                        isInvalid: false,
                        value: photoAttribution,
                        changeHandler: (value) => { setPhotoAttribution(value) },
                      },
                      {
                        width: "2",
                        label: "Photo License URL",
                        defaultValue: undefined,
                        placeholder: "No Photo License Set",
                        isInvalid:
                          photoAttributionLicense &&
                          (
                            (
                              (photoAttribution?.toString().match(/^[0-9a-fA-F]{24}$/))
                              && photoAttributionLicense !== "Roamli"
                            )
                            || (
                              !(photoAttribution?.toString().match(/^[0-9a-fA-F]{24}$/))
                              && !IsValidUrl(photoAttributionLicense)
                            )
                          ),
                        value: photoAttributionLicense,
                        changeHandler: (value) => { setPhotoAttributionLicense(value) },
                        invalidFeedback: "Enter Valid URL that starts with 'http' or 'https://'.",
                      }
                    ]}
                  />
                  : null
              }
              <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>Challenge Details</h4>
              <div className={"form-row mt-1"} style={{ width: '100%' }}>
                <ChallengeFormFieldRow
                  resetHandler={() => { setTitle(challenge?.title); }}
                  isResetDisabled={title === challenge?.title}
                  width="4"
                  fields={[
                    {
                      width: "10",
                      label: "Title",
                      defaultValue: undefined,
                      placeholder: "No Title Set",
                      isInvalid: title === "" || title === null || title === undefined,
                      value: title,
                      changeHandler: (value) => { setTitle(value) },
                      invalidFeedback: "Enter a Title.",
                      validFeedback: null
                    }
                  ]}
                />
                <ChallengeFormFieldRow
                  resetHandler={() => { setPoints(GetCurrentPointValue(challenge)); }}
                  isResetDisabled={points === GetCurrentPointValue(challenge)}
                  width="3"
                  fields={[
                    {
                      width: "4",
                      type: "number",
                      label: "Points",
                      defaultValue: undefined,
                      placeholder: "No Points Set",
                      isInvalid: isNaN(points) || !points || points < 0,
                      value: points,
                      changeHandler: (value) => { setPoints(value) },
                      invalidFeedback: getNumberErrorMessage(points),
                      validFeedback: null
                    }
                  ]}
                />
              </div>
              <ChallengeFormFieldRow
                resetHandler={() => { setDescription(challenge?.description); }}
                isResetDisabled={description === challenge?.description}
                fields={[
                  {
                    width: "8",
                    label: "Description",
                    type: "textarea",
                    inputStyle: { height: 200 },
                    defaultValue: undefined,
                    placeholder: "No Description Set",
                    isInvalid: description === "" || description === null || description === undefined,
                    value: description,
                    changeHandler: (value) => { setDescription(value) },
                    invalidFeedback: "Enter a Description.",
                    validFeedback: null
                  }
                ]}
              />
              <div className={"form-row mt-3 ml-0"}>
                <ChallengeTypeSelection
                  isResetDisabled={challengeType === challenge?.challengeType}
                  resetHandler={() => {
                    setChallengeType(challenge?.challengeType);
                    setChallengeSubTypes(challenge.challengeSubTypes)
                  }}
                  width="2"
                  challengeTypes={[challengeType]}
                  label="Primary Type"
                  canSelectOnlyOne={true}
                  onSelectHandler={(newChallengeType) => {
                    if (newChallengeType !== challengeType) {
                      const details = CHALLENGE_TYPE_DETAILS[GetFilterNameFromNumber(newChallengeType)];
                      setChallengeType(newChallengeType);
                      setChallengeSubTypes(details.subTypes);
                    }
                  }
                  } />
                <ChallengeTypeSelection
                  isResetDisabled={challengeSubTypes.sort().join(',') === challenge?.challengeSubTypes.sort().join(',')}
                  resetHandler={() => { setChallengeType(challenge?.challengeType); setChallengeSubTypes(challenge.challengeSubTypes) }}
                  challengeTypes={challengeSubTypes}
                  label="Secondary Types"
                  requiredChallengeTypes={primaryTypeDetails?.subTypes}
                  onSelectHandler={setChallengeSubTypes} />
              </div>
              <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>Answer Details</h4>
              <div className={"mt-1"} style={{ width: '100%' }}>
                <Col md="3" className="ml-0 pl-0">
                  <label
                    className="form-control-label"
                    id="tooltipAnswerType"
                  >
                    Answer Type:
                    <i className="far fa-question-circle ml-1" />
                  </label>
                  <Row className="ml-0">
                    <div className="custom-control custom-radio mb-3">
                      <input
                        className="custom-control-input"
                        id="customRadioAnswerType1"
                        name="custom-radio-answer-type"
                        type="radio"
                        onChange={(e) => { setAnswerType(ANSWER_TYPE.PICTURE) }}
                        checked={answerType === ANSWER_TYPE.PICTURE}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioAnswerType1"
                      >
                        Submit a Photo
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-3 ml-3">
                      <input
                        className="custom-control-input"
                        id="customRadioAnswerType2"
                        name="custom-radio-answer-type2"
                        type="radio"
                        onChange={(e) => { setAnswerType(ANSWER_TYPE.TEXT) }}
                        checked={answerType === ANSWER_TYPE.TEXT}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioAnswerType2"
                      >
                        Answer a Question
                      </label>
                    </div>
                  </Row>
                </Col>
                <UncontrolledTooltip delay={0} target="tooltipAnswerType">
                  The player will have to submit either a photo or a text answer based on what you choose. If you choose a text answer, put the question in the Description field above or in the Extra Description field for this Challenge on the Create an Event page.
                </UncontrolledTooltip>
              </div>
              {answerType === ANSWER_TYPE.TEXT && <TextAnswers
                textQuestion={textQuestion}
                textQuestionHandler={setTextQuestion}
                textAnswers={textAnswer ?? []}
                textAnswersHandler={setTextAnswer}
                isTextQuestionResetDisabled={challenge?.textQuestion === textQuestion}
                isTextAnswerResetDisabled={JSON.stringify(textAnswer) === JSON.stringify(challenge?.textAnswer)}
                textAnswerResetHandler={() => { setTextAnswer(challenge?.textAnswer); }}
                textQuestionResetHandler={() => { setTextQuestion(challenge?.textQuestion); }} />}
              <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>Geographic Details</h4>
              <div className={"form-row mt-1 pb-4"} style={{ width: '100%', borderBottom: "1px solid black" }}>
                <div className="mb-3">
                  <POIAutoComplete 
                  title={'Search for an Address or Point of Interest'}
                  handlePlaceSelect={handlePlaceSelect}
                  placeholder={"Search"}
                  />
                  <GoogleApiWrapper
                    lat={parseFloat(latitude)}
                    lng={parseFloat(longitude)}
                    containerStyle={
                      {
                        position: "relative",
                        height: 400,
                        width: isTabletOrMobile ? window.innerWidth : 400,
                        maxWidth: "100%",
                        marginLeft: isTabletOrMobile ? -20 : 0
                      }}
                    name={title}
                    picUrl={source}
                    challengeType={challengeType}
                    radius={radius}
                    onClickHandler={(coords) => { setLatitude(coords.lat); setLongitude(coords.lng) }} />
                  <Col md="12" className="ml-0 pl-0 mt-4">
                    <label
                      className="form-control-label"
                      id="toolTipNoLocation"
                    >
                      Requires Location:
                      <i className="far fa-question-circle ml-1" />
                    </label>
                    <div className="custom-control custom-radio mb-3">
                      <input
                        className="custom-control-input"
                        id="customRadioNoLocation1"
                        name="custom-radio-no-location"
                        type="radio"
                        onChange={(e) => { setNoLocation(false) }}
                        checked={!noLocation}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioNoLocation1"
                      >
                        Show Location
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-3">
                      <input
                        className="custom-control-input"
                        id="customRadioNoLocation2"
                        name="custom-radio-no-location"
                        type="radio"
                        onChange={(e) => { setNoLocation(true) }}
                        checked={noLocation}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioNoLocation2"
                      >
                        No Location
                      </label>
                    </div>
                  </Col>
                  <UncontrolledTooltip delay={0} target="toolTipNoLocation">
                    "No Location" should be selected only if this Challenge is being put into a Scavenger Hunt and you want it to be submittable from anywhere. This will keep the Challenge from appearing on the map.
                  </UncontrolledTooltip>
                </div>
                <Col className={isTabletOrMobile ? "ml-0" : "ml-5"}>
                  <ChallengeFormFieldRow
                    resetHandler={() => { setLongitude(challenge.long); setLatitude(challenge.lat) }}
                    isResetDisabled={!areLatLongChanged()}
                    fields={[
                      {
                        width: "3",
                        label: "Latitude",
                        defaultValue: undefined,
                        disabled: false,
                        placeholder: "No Latitude Set",
                        isInvalid: !isValidLatitude(),
                        value: latitude,
                        changeHandler: (value) => { setLatitude(value) },
                        invalidFeedback: "Enter valid Latitude between -90 and 90.",
                        validFeedback: "Looks good!"
                      },
                      {
                        width: "3",
                        label: "Longitude",
                        defaultValue: undefined,
                        disabled: false,
                        placeholder: "No Longitude Set",
                        isInvalid: !isValidLongitude(),
                        value: longitude,
                        changeHandler: (value) => { setLongitude(value) },
                        invalidFeedback: "Enter valid Longitude between -180 and 180.",
                        validFeedback: "Looks good!"
                      }
                    ]}
                  />
                  <div className={"form-row mt-1"} style={{ width: '100%' }}>
                    <ChallengeFormFieldRow
                      resetHandler={() => { setRadius(challenge?.radius); }}
                      isResetDisabled={radius === challenge?.radius}
                      width="2"
                      fields={[
                        {
                          width: "8",
                          type: "number",
                          label: "Radius",
                          defaultValue: undefined,
                          disabled: noLocation,
                          placeholder: "No Radius Set",
                          isInvalid: isNaN(radius) || !radius || radius < 0,
                          value: radius,
                          changeHandler: (value) => { setRadius(parseInt(value)) },
                          invalidFeedback: getNumberErrorMessage(radius),
                          validFeedback: null
                        }
                      ]}
                    />
                    <ChallengeFormFieldRow
                      resetHandler={() => { setAddress(challenge?.address?.displayedAddress); }}
                      isResetDisabled={address === challenge?.address?.displayedAddress}
                      width="4"
                      fields={[
                        {
                          width: "10",
                          label: "Displayed Address",
                          defaultValue: undefined,
                          disabled: noLocation,
                          placeholder: "No Address Set",
                          isInvalid: address === "" || address === null || address === undefined,
                          value: noLocation ? "Anywhere" : address,
                          changeHandler: (value) => { setAddress(value) },
                          invalidFeedback: "Enter an address to display (i.e. Chicago, IL, USA).",
                          validFeedback: null
                        }
                      ]}
                    />
                  </div>
                </Col>
              </div>
              <div className="pb-4 mb-4 ml-0" style={{ borderBottom: "1px solid black" }}>
                <ExternalLinks
                  externalLinks={externalLinks}
                  externalLinksHandler={setExternalLinks}
                  isResetDisabled={JSON.stringify(externalLinks) === JSON.stringify(challenge?.externalLinks)}
                  resetHandler={() => { setExternalLinks(challenge?.externalLinks); }} />
              </div>
              <div className="pb-4 mb-4 ml-0" style={{ borderBottom: "1px solid black" }}>
                <>
                  <h3 className="mb-0 mt-3">{"YouTube Link (Optional)"}</h3>
                  <YouTubeLinkRow
                    youTubeLink={youTubeLink}
                    maxVideoWidth={isTabletOrMobile ? undefined : 500}
                    isResetDisabled={JSON.stringify(challenge?.youTubeLink) === JSON.stringify(youTubeLink)}
                    updateHandler={(value) => { setYouTubeLink(value) }}
                    resetHandler={() => { setYouTubeLink(challenge?.youTubeLink) }}
                  />
                </>
              </div>
              {
                authenticatedAdmin ?
                  <>
                    <h4 className={"mt-4 mb-3"} >Admin Controls</h4>
                    <div className="form-row mb-4 ml-0" style={{ borderBottom: "1px solid black" }}>
                      <Col md="3" className="ml-0 pl-0">
                        <label
                          className="form-control-label"
                          id="toolTipIsPersonal"
                        >
                          Official Status:
                          <i className="far fa-question-circle ml-1" />
                        </label>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioIsPersonal1"
                            name="custom-radio-is-personal"
                            type="radio"
                            onChange={(e) => { setIsPersonal(true) }}
                            checked={isPersonal}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioIsPersonal1"
                          >
                            Personal Challenge
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioIsPersonal2"
                            name="custom-radio-is-personal"
                            type="radio"
                            onChange={(e) => { setIsPersonal(false) }}
                            checked={!isPersonal}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioIsPersonal2"
                          >
                            Official Roamli Challenge
                          </label>
                        </div>
                      </Col>
                      <UncontrolledTooltip delay={0} target="toolTipIsPersonal">
                        "Official Roamli Challenge" should only be chosen if this is being made into an official Roamli Challenge that will appear on the map for everyone. Everything else should be "Personal Challenge".
                      </UncontrolledTooltip>
                      <Col md="3" className="ml-0 pl-0" >
                        <label
                          className="form-control-label"
                          id="tooltip12475020112"
                        >
                          Active:
                          <i className="far fa-question-circle ml-1" />
                        </label>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioActive1"
                            name="custom-radio-active"
                            type="radio"
                            onChange={(e) => { setIsActive(true) }}
                            checked={isActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioActive1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioActive2"
                            name="custom-radio-active"
                            type="radio"
                            onChange={(e) => { setIsActive(false) }}
                            checked={!isActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioActive2"
                          >
                            Inactive
                          </label>
                        </div>
                      </Col>
                      <UncontrolledTooltip delay={0} target="tooltip12475020112">
                        If disabled, hides the event from all users.
                      </UncontrolledTooltip>
                      <Col md="3" className="ml-0 pl-0" >
                        <label
                          className="form-control-label"
                          id="toolTipChallengeReviewStatus"
                        >
                          Challenge Review Status:
                          <i className="far fa-question-circle ml-1" />
                        </label>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus1"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.IN_REVIEW) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.IN_REVIEW}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus1"
                          >
                            In Review
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus2"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.APPROVED) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.APPROVED}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus2"
                          >
                            Approved
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus3"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.DECLINED_INCORRECT) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.DECLINED_INCORRECT}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus3"
                          >
                            Declined: Incorrect
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus4"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.DECLINED_DUPLICATE) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.DECLINED_DUPLICATE}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus4"
                          >
                            Declined: Duplicate
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus5"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.DECLINED_UNSAFE) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.DECLINED_UNSAFE}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus5"
                          >
                            Declined: Unsafe
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioChallengeReviewStatus6"
                            name="custom-radio-challenge-review-status"
                            type="radio"
                            onChange={(e) => { setChallengeReviewStatus(CHALLENGE_REVIEW_STATUS.DECLINED_INAPPROPRIATE) }}
                            checked={challengeReviewStatus === CHALLENGE_REVIEW_STATUS.DECLINED_INAPPROPRIATE}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioChallengeReviewStatus6"
                          >
                            Declined: Inappropriate
                          </label>
                        </div>
                      </Col>
                      <UncontrolledTooltip delay={0} target="toolTipChallengeReviewStatus">
                        Set Review Status of Challenges that are Official Roamli Challenges.
                      </UncontrolledTooltip>
                      {
                        !picChallengeUrl && challenge.userSubmittedPicChallengeUrl ?
                          <Col md="12" className="ml-0 pl-0 mb-4" >
                            <div className="imagePreviewRoot">
                              <div className="imagePreviewContainer mb-3">
                                <img src={challenge.userSubmittedPicChallengeUrl} className="imagePreview" />
                              </div>
                              <Button
                                className="btn-icon-only"
                                color="primary"
                                type="button"
                                disabled={picChallengeUrl === challenge.userSubmittedPicChallengeUrl}
                                style={{ height: 46, width: 160 }}
                                onClick={() => { setPicChallengeUrl(challenge.userSubmittedPicChallengeUrl); setImage(null); setUseLocalImage(false); }}
                              >
                                {
                                  picChallengeUrl === challenge.userSubmittedPicChallengeUrl ?
                                    "Selected"
                                    : "Set as Image"
                                }
                              </Button>
                            </div>
                          </Col>
                          : null
                      }
                    </div>
                  </>
                  : null
              }
              <>
                <Button
                  color="success"
                  type="button"
                  onClick={async () => { await handleSubmission() }}
                  disabled={isLoading}
                  style={{ minWidth: 160, minHeight: 55 }}
                >
                  {isLoading ? <Spinner color={"secondary"} /> : props.createMode ? "CREATE CHALLENGE" : "APPLY CHANGES"}
                </Button>
                {
                  (!props.createMode || (props.createMode && props.creatingDuplicate)) && <Button
                    color="primary"
                    type="button"
                    size="md"
                    onClick={() => {
                      props.duplicateHandler && props.duplicateHandler();
                      document.documentElement.scrollTop = 0;
                      document.scrollingElement.scrollTop = 0;
                    }}
                    disabled={isLoading}
                  // style={{ minWidth: 160, minHeight: 55 }}
                  >
                    {props.creatingDuplicate ? "BACK TO ORIGINAL" : "MAKE A COPY"}
                  </Button>
                }
                {
                  challengeReviewStatus !== CHALLENGE_REVIEW_STATUS.APPROVED && !props.createMode ?
                    <h4 className={"mt-2"}>{"Challenge Review Status: " + CHALLENGE_REVIEW_STATUS_DISPLAY[challenge.challengeReviewStatus.toString()]}</h4>
                    : null
                }
              </>
            </Form>
          </CardBody>
        </Card >
        {alert}
      </Container >
    </>
  );
}

export default ChallengeForm;
