import React from 'react';
import { TextField } from '@material-ui/core';
import { usePlacesWidget } from 'react-google-autocomplete';

const POIAutoComplete = ({ title, handlePlaceSelect, placeholder }) => {
  // Set up the usePlacesWidget hook to bind the autocomplete to the input field
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo",
    onPlaceSelected: handlePlaceSelect,
    options: {
      types: [], // Customize this to filter by specific place types (e.g., addresses, cities)
    },
  });

  return (
    <div>
      <TextField
        inputRef={ref} // Bind the ref from usePlacesWidget to the TextField input
        label={title ?? "Search for any place"}
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder={placeholder ?? "Search for a place"}
      />
    </div>
  );
};

export default POIAutoComplete;
